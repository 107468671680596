<template>
  <v-container>
    <v-row justify="space-around">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="primary" dark
            >Reporte de Consumo de Combustible</v-toolbar
          >
          <v-progress-linear
            v-if="loading"
            loading="loading"
            indeterminate
            color="teal"
          ></v-progress-linear>
          <div class="text-h2 pa-8">
            <v-form ref="myform" v-model="valid" :lazy_validation="false">
              <v-row>
                <v-col cols="12" md="12" v-if="!isX">
                  <v-text-field
                    label="Vehículo"
                    v-model="body.vehicle_id"
                    :rules="required"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <date-field
                    label="Del"
                    v-model="body.start_date"
                    required
                  ></date-field>
                </v-col>
                <v-col cols="12" md="6">
                  <date-field
                    label="Al"
                    v-model="body.end_date"
                    required
                  ></date-field>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cerrar </v-btn>
            <v-btn
              v-if="!isX"
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="getPDF()"
            >
              Generar Reporte PDF
            </v-btn>
            <v-btn
              v-if="isX"
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="getXslx()"
            >
              Generar Reporte Excel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import rules from "@/utils/form.rules";
import requests from "@/services/requests";
import blobResponse from "@/utils/response.blob";
export default {
  components: {
    DateField: () => import("@/components/fields/DateField.vue"),
  },
  props: {
    dialog: { type: Boolean, required: true },
    isX: { type: Boolean, required: true },
  },
  data() {
    return {
      vehicles: [],
      body: {
        end_date: undefined,
        start_date: undefined,
        vehicle_id: undefined,
      },
      valid: false,
      loading: false,
      isDateRangeValid: false,
      // rules
      required: [rules.required],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    resetForm() {
      (this.body = {
        end_date: undefined,
        start_date: undefined,
        vehicle_id: undefined,
      }),
        this.$refs.myform.reset();
      this.valid = false;
      this.isDateRangeValid = false;
    },
    vehicleList() {
      requests.get("/api/catalogs/vehicles/").then((res) => {
        this.vehicles = res.data.results;
      });
    },
    getPDF() {
      this.loading = true;
      this.valid = false;
      if (this.$refs.myform.validate()) {
        var api =
          "api/pdf/diesel-summary/?start=" +
          this.body.start_date +
          "&end=" +
          this.body.end_date +
          "&vehicle=" +
          this.body.vehicle_id.toUpperCase().split(", ").join(",");

        requests.get(api).then((response) => {
          if (response.status == 200) {
            blobResponse(response.data, "application/pdf");
            this.resetForm();
            this.$emit("close");
            this.$toasted.global.info({ message: "El reporte fue generado" });
          } else {
            this.valid = true;
            this.$toasted.global.error();
          }
          this.loading = false;
        });
      }
    },
    getXslx() {
      this.loading = true;
      this.valid = false;
      if (this.$refs.myform.validate()) {
        requests.postBlob("api/diesel/xlsx/", this.body).then((res) => {
          if (res.status == 200) {
            blobResponse(
              res.data,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
            this.resetForm();
            this.$emit("close");
            this.$toasted.global.info({ message: "El reporte fue generado" });
          } else {
            this.valid = true;
            this.$toasted.global.error();
          }
        });
        this.loading = false;
      }
    },
  },
  watch: {
    body: {
      handler: function (value) {
        if (value.start_date != undefined) {
          if (
            value.start_date.length === 10 &&
            value.end_date != undefined &&
            value.end_date.length === 10
          ) {
            if (
              new Date(value.start_date).getTime() <=
              new Date(value.end_date).getTime()
            ) {
              this.valid = true;
            } else {
              this.valid = false;
              alert("El rango de fecha no es valido");
              this.body.end_date = "";
            }
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.vehicleList();
  },
};
</script>